import React from "react";
import { Route, useLocation } from "react-router-dom";

import { PageNames } from "../../types/page";
import isIntersectionWith from "../../helpers/is-intersection-with";
import { useHideRoute } from "../../context/hide-route";

interface AutoHiddenProps {
  component: React.ComponentType;
  onlyIn: PageNames[];
}

const AutoHidden: React.FC<AutoHiddenProps> = ({
  component: Component,
  onlyIn,
  ...rest
}) => {
  const { pathname } = useLocation();
  const [isRouteHidden] = useHideRoute();

  const pathnameParts = pathname.split("/");

  const isHidden = isIntersectionWith(pathnameParts, onlyIn);

  if (isRouteHidden || isHidden) {
    return null;
  }

  return <Route {...rest} component={Component} />;
};

export default AutoHidden;
