/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React from "react";
import { useImmerReducer } from "use-immer";
import ToastsManager from "./toasts-manager";

const ToastContext = React.createContext();

let toastUniqueId = 0;

const initialState = {
  toasts: [],
};

const action = {
  ADD: "ADD",
  REMOVE: "REMOVE",
};

const reducer = (draft, [type, payload]) => {
  switch (type) {
    // Handle add action
    case action.ADD: {
      draft.toasts.push({
        id: `${++toastUniqueId}`,
        ...payload,
      });
      return draft;
    }

    // Handle remove action
    case action.REMOVE: {
      draft.toasts = draft.toasts.filter((elm) => elm.id !== payload);
      return draft;
    }
    default:
      return draft;
  }
};

const ToastsProvider = (props) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);

  // creat a new toast
  const addToast = (payload) => dispatch([action.ADD, payload]);

  // remove existing toast
  const removeToast = (id) => dispatch([action.REMOVE, id]);

  // state
  const { toasts } = state;
  const showToasts = toasts.length > 0;
  return (
    <React.Fragment>
      <ToastContext.Provider
        value={{ toasts, addToast, removeToast }}
        {...props}
      />
      {showToasts && <ToastsManager {...{ removeToast, toasts }} />}
    </React.Fragment>
  );
};

export { ToastContext, ToastsProvider };
