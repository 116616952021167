import styled from "styled-components";

export const GRID_GAP = 1;
export const NAV_HEIGHT = 54;
export const MAX_WIDTH = 1024;
export const FOR_MEDIA_LARGE_UP = MAX_WIDTH;
export const FOR_MEDIA_MEDIUM_UP = 768;
export const FOR_MEDIA_PHONE_ONLY = 599;

// Divider
export const Divider = styled.div`
  height: 1px;
  margin: 0.4rem 0rem;
  background-color: #9aa1af;
  width: 100%;
  border-radius: 4rem;
`;

// tag
export const Tag = styled.div.attrs({
  className: "tag",
})<{ fill: string | null }>`
  color: white;
  font-size: 0.5rem;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  border-radius: 3rem;
  padding: 0.1rem 0.6rem;
  background-color: ${(props) => (props.fill ? props.fill : "#667EEA")};
  span {
    line-height: 100%;
    letter-spacing: 0.022rem;
    text-transform: capitalize;
  }
`;

// Custom scroll Bar
export const CustomScrollBar = styled.div`
  &::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    display: block;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border: 6px solid transparent;
    display: block;
    background: #cfd2df;
    border-radius: 10px;
    background-clip: padding-box;
  }
`;
