import styled, { keyframes } from "styled-components";
import { transparentize } from "polished";

export const spinnerAnimation = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  vertical-align: middle;
`;

export const FullWidthWrapper = styled(SpinnerWrapper)`
  margin-top: 2rem;
`;

export const SpinnerStyle = styled.div`
  display: block;
  pointer-events: none;
  animation: ${spinnerAnimation} 0.8s linear infinite;
  transform-origin: center center;
  pointer-events: none;
  user-select: none;
  svg {
    display: block;
  }
  path {
    fill-opacity: 0;
  }
  .spinner-track {
    stroke: ${(props) => transparentize(0.8, props.color)};
  }
  .spinner-head {
    stroke: ${(props) => props.color};
    transform-origin: center;
    transition: stroke-dashoffset 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
    stroke-linecap: round;
  }
`;

export const CardSpinnerStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 280px;
  border-radius: 6px;
  padding: 2rem;
  margin: 1rem auto 0rem;
  background-color: transparent;
  user-select: none;
  pointer-events: none;
  h2 {
    font-size: 0.8rem;
    font-weight: 600;
    color: #313155;
    letter-spacing: 0.023rem;
    line-height: 1.4rem;
    margin-top: 1rem;
    text-align: center;
    text-transform: capitalize;
  }
`;
