import gql from "graphql-tag";

import { CURRENT_USER_FRAGMENT } from "../fragments";

const CURRENT_USER = gql`
  query CURRENT_USER {
    currentUser {
      ...CurrentUserFragment
    }
  }
  ${CURRENT_USER_FRAGMENT}
`;

export { CURRENT_USER };
