import { ChallengeType } from "../generated/apollo-hooks";

/* APP */
export const IS_PROD = process.env.NODE_ENV === "production";
export const APP_DOMAIN = IS_PROD
  ? "https://natanada.natanada-team.now.sh"
  : "http://localhost:4000";
export const CHALLENGE_TYPES: ChallengeType[] = [
  "LetterLesson",
  "GrammarLesson",
  "VocabularyLesson",
  "ReadingLesson",
  "Quiz",
];

/* IMAGE FILE LIMITS */
export const USER_MAX_IMAGE_SIZE_BYTES = 5000000;
export const ALLOWED_IMAGE_TYPES = ["image/png", "image/jpg", "image/jpeg"];

/* CLOUDINARY */
export const CLOUDINARY_BASE_URL =
  "https://res.cloudinary.com/natanada/image/fetch/";
export const CLOUDINARY_BASE_TRANSFORM =
  "c_fill,f_auto,q_auto,g_auto,fl_progressive";
export const SMALL_IMAGE_TRANSFORM = `${CLOUDINARY_BASE_TRANSFORM},w_73,h_73/`;
export const MEDIUM_IMAGE_TRANSFORM = `${CLOUDINARY_BASE_TRANSFORM},w_200,h_200/`;
