import styled from "styled-components";
import { Link } from "react-router-dom";

export const LogoLink = styled(Link)`
  grid-area: logo;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #395aed;
  svg {
    height: 1.7rem;
    width: auto;
    fill: currentColor;
  }
`;
