import React from "react";
import styled from "styled-components";
import { ErrorMessage } from "formik";

import { ReactComponent as AlertIcon } from "remixicon/icons/System/alert-fill.svg";

const Input = ({ field, labelFor, ...rest }) => (
  <InputWrapper>
    <label htmlFor={field.name}>
      {labelFor && (
        <LabelSpan>
          {typeof labelFor === "function" ? labelFor() : labelFor}
        </LabelSpan>
      )}
      <InputStyle id={field.name} {...rest} {...field} autoComplete="off" />
    </label>
    <ErrorMessage name={field.name}>
      {(msg) => (
        <InputError>
          <AlertIcon /> {msg}
        </InputError>
      )}
    </ErrorMessage>
  </InputWrapper>
);

export default Input;

const InputStyle = styled.input`
  width: 100%;
  height: 44px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 500;
  color: black;
  background: transparent;
  border-image: none;
  border: 2px solid #e2e8f0;
  border-radius: 5px;
  transition: all 0.15s ease-in-out;
  &:hover {
    border-color: #cacdd1;
  }
  &:focus {
    border-color: #557cf3;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
  p,
  svg {
    vertical-align: middle;
    display: inline-block;
  }
`;

const LabelSpan = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: 500;
  color: #19193b;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
`;

const InputError = styled.p`
  font-size: 0.8rem;
  color: red;
  text-align: left;
  margin-left: 10px;
  margin-top: 3px;
  svg {
    fill: currentColor;
    width: 1rem;
    height: 1rem;
    margin-right: 0.3rem;
  }
`;
