import styled from "styled-components";

import { GRID_GAP, MAX_WIDTH, FOR_MEDIA_LARGE_UP } from "../style";

export const HeaderStyle = styled.header`
  grid-area: navbar;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
  border-bottom: 1px solid #e0e5ed;
`;

export const HeaderResponsive = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "logo . profile";
  grid-template-rows: 1fr;
  grid-column-gap: ${GRID_GAP}rem;
  align-items: center;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  padding: 0.5rem 1rem;
  @media (min-width: ${FOR_MEDIA_LARGE_UP}px) {
    padding-left: 0rem;
    padding-right: 0rem;
  }
`;

export const ButtonWrapper = styled.div`
  grid-area: profile;
`;
