import { ApolloClient } from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import fragmentTypes from "./generated/fragments/fragmentTypes";

// Create a new IntrospectionFragment matcher and passing fragmentTypes from the server
// for more about this: https://www.apollographql.com/docs/react/advanced/fragments/#fragment-matcher
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: fragmentTypes,
});

const link = createUploadLink({
  uri: "/graphql",
  credentials: "include",
});

const cache = new InMemoryCache({
  dataIdFromObject: (data: any): string => {
    // eslint-disable-next-line no-underscore-dangle
    switch (data.__typename) {
      case "User":
        return data.username;
      default:
        // eslint-disable-next-line no-underscore-dangle
        return data.id || data._id;
    }
  },
  fragmentMatcher,
});

export const client = new ApolloClient({
  assumeImmutableResults: true,
  link,
  cache,
});
