import React from "react";

import { AppWrapperStyle } from "./style";

interface AppWrapperProps {
  children: React.ReactNode;
}

const AppWrapper: React.FC<AppWrapperProps> = (props) => (
  <AppWrapperStyle {...props} />
);

export default AppWrapper;
