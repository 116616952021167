import React from "react";

export interface HideRouteContextValue {
  isHidden: boolean;
  setRouteHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

// Hide route context that holds context value
export const HideRouteContext = React.createContext<HideRouteContextValue>(
  null!,
);

// Context provider
export const HideRouteContextProvider: React.FC = (props) => {
  const [isHidden, setRouteHidden] = React.useState(false);

  // Memoized context values
  const value = React.useMemo(() => ({ isHidden, setRouteHidden }), [
    isHidden,
    setRouteHidden,
  ]);

  // Pass the value to the context
  return <HideRouteContext.Provider value={value} {...props} />;
};

// Hook
export const useHideRoute = (
  defaultValue = false,
): readonly [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const { isHidden, setRouteHidden } = React.useContext(HideRouteContext);

  // Set default value if passed
  React.useEffect(() => {
    setRouteHidden(defaultValue);
    return () => setRouteHidden(false);
  }, [defaultValue, setRouteHidden]);

  return [isHidden, setRouteHidden] as const;
};
