import styled from "styled-components";

import { FOR_MEDIA_PHONE_ONLY } from "../style";

export const ToastManagerStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: ${FOR_MEDIA_PHONE_ONLY}px;
  width: 100%;
  position: fixed;
  top: 3rem;
  right: 0rem;
  z-index: 5500;
  padding: 0.5rem;
  pointer-events: none;
  @media (max-width: ${FOR_MEDIA_PHONE_ONLY}px) {
    bottom: 0rem;
    top: auto;
    /* Make the toast full width on mobile */
    > div {
      width: calc(100% - 1rem);
    }
  }
`;

export const ToastBaseStyle = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0.75rem 2rem 0.75rem 1rem;
  margin: 0.5rem;
  position: relative;
  overflow: hidden;
  color: white;
  text-align: left;
  border-radius: 6px;
  background-color: ${(props) => props.color};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  pointer-events: all;
  > svg {
    width: 1.25rem;
    height: 1.25rem;
    fill: currentColor;
    display: inline-block;
    vertical-align: middle;
    margin-top: 0.15rem;
    margin-right: 0.75rem;
    flex-shrink: 0;
    backface-visibility: hidden;
  }
`;

export const Content = styled.div`
  flex: 1 1 0%;
`;

export const Title = styled.div`
  font-size: 0.95rem;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.03rem;
`;
export const Description = styled.div`
  font-size: 0.8rem;
  line-height: 1.7;
  font-weight: 400;
`;

export const IconButton = styled.button.attrs({ type: "button" })`
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  outline: none;
  border: none;
  border-radius: 4px;
  background: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
  &:hover {
    background-color: #ffffff29;
  }
  > svg {
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    flex-shrink: 0;
    fill: currentColor;
    backface-visibility: hidden;
  }
`;
