import React from "react";

import { ReactComponent as AppLogo } from "../icons/svg/logo.svg";

import { LogoLink } from "./style";

const Logo = () => (
  <LogoLink to="/">
    <AppLogo />
  </LogoLink>
);

export default Logo;
