import React from "react";

import { useUser } from "../../context/user-context";
import IntroduceUserDialog from "./dialog";
import { useEditUserMutation } from "../../generated/apollo-hooks";
import Modal from "../modal";
import { CURRENT_USER } from "../../graphql/queries/user";

const ConfirmEmailAndUsername: React.FC = () => {
  const currentUser = useUser();
  const [isOnboarding, setIsOnboarding] = React.useState(false);

  React.useEffect(() => {
    if (currentUser && !currentUser.introduced && !isOnboarding) {
      setIsOnboarding(true);
    }
  }, [currentUser, isOnboarding]);

  const [editUser] = useEditUserMutation({
    refetchQueries: [{ query: CURRENT_USER }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setIsOnboarding(false);
    },
  });

  if (!currentUser || currentUser.introduced) return null;

  return (
    <Modal isClosable={false} open={isOnboarding} onClose={() => {}}>
      <IntroduceUserDialog editUser={editUser} currentUser={currentUser} />
    </Modal>
  );
};

export default ConfirmEmailAndUsername;
