import React from "react";
import PropTypes from "prop-types";

import stringToHex from "../../helpers/string-to-hex";
import { Wrapper, StyledImage } from "./style";

const Avatar = ({ name, src, ...props }) => {
  return (
    <Wrapper color={stringToHex(name)} {...props}>
      <StyledImage alt={name} src={src} />
    </Wrapper>
  );
};

Avatar.defaultProps = {
  size: 32,
};

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  size: PropTypes.number,
};

export default Avatar;
