import React from "react";
import Tippy, { TippyProps } from "@tippy.js/react";
import styled from "styled-components";

import "tippy.js/dist/tippy.css";

const ToolTip: React.FC<TippyProps> = (props) => <StyledTippy {...props} />;

export default ToolTip;

const StyledTippy = styled(Tippy)`
  &.tippy-tooltip {
    background: transparent;
  }
  &.tippy-tooltip[data-placement^="bottom"] > .tippy-arrow {
    border-bottom-color: white;
  }
  .tippy-content {
    border-radius: 6px;
    padding: 0rem;
    color: #19193b;
    background-color: white;
    box-shadow: 0px 10px 40px hsla(0, 0%, 0%, 0.2),
      0px 3px 6px hsla(0, 0%, 0%, 0.1);
  }
`;
