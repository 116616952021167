const isIntersectionWith = (arr: string[], anotherArr: string[]): boolean => {
  let res = false;
  arr.forEach((item) => {
    if (anotherArr.includes(item)) {
      res = true;
    }
  });
  return res;
};

export default isIntersectionWith;
