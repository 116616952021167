import React from "react";
import PropTypes from "prop-types";

import { ProfileStyle, UserWidget, ProfileName } from "./style";
import ToolTip from "../tooltip";
import Avatar from "../avatar";
import ProfileMenu from "./profile-menu";
import { CLOUDINARY_BASE_URL, SMALL_IMAGE_TRANSFORM } from "../../constants";

const tipProps = {
  interactive: true,
  placement: "top-end",
  trigger: "click",
  flip: false,
  aria: null,
  distance: 4,
  duration: 0,
  arrow: false,
  appendTo: (elm) => elm,
};

const Profile = ({ user }) => {
  const avatar = `${CLOUDINARY_BASE_URL}${SMALL_IMAGE_TRANSFORM}${user.avatar}`;
  return (
    <ProfileStyle>
      <ToolTip
        content={<ProfileMenu user={{ ...user, avatar }} />}
        {...tipProps}
      >
        <div>
          <UserWidget>
            <Avatar src={avatar} name={user.username} size={32} />
            <ProfileName>{user.fullName}</ProfileName>
          </UserWidget>
        </div>
      </ToolTip>
    </ProfileStyle>
  );
};

Profile.propTypes = {
  user: PropTypes.shape({
    fullName: PropTypes.string,
    username: PropTypes.string,
    avatar: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default Profile;
