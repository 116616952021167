import styled from "styled-components";

export const BaseStyle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  font-size: ${(props) => (props.size === "small" ? "0.85rem" : "1rem")};
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.02rem;
  text-transform: capitalize;
  color: white;
  border-radius: 2rem;
  padding: 0.55rem 1.5rem;
  padding: ${(props) =>
    props.size === "small" ? "0.4rem 0.9rem" : "0.55rem 1.5rem"};
  background-color: transparent;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
`;

export const PrimaryButtonStyle = styled(BaseStyle)`
  border: 1px solid #567bf3;
  background: #567bf3;
  transition: background-color 0.2s ease-in-out 0s,
    border-color 0.2s ease-in-out 0s;
  &:hover {
    border-color: #2e57de;
    background: #2e57de;
  }
  &:focus {
    border-color: #2e57de;
  }
`;

export const OutlineButtonStyle = styled(BaseStyle)`
  border: 1px solid #567bf3;
  color: #567bf3;
  transition: background-color 0.2s ease-in-out 0s,
    box-shadow 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s;
  &:hover {
    background: hsla(226, 73%, 53%, 0.1);
  }
  &:focus {
    box-shadow: 0px 0px 0px 2px #567bf3;
  }
`;
