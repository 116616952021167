import gql from "graphql-tag";

export const LEVEL_INFO_FRAGMENT = gql`
  fragment LevelInfo on Level {
    id
    title
    levelIndex
    avatar
    completed
    accessible
    isLastLevel
    totalMaterials
  }
`;

export const PROGRESS_IN_LEVEL_FRAGMENT = gql`
  fragment ProgressInLevelFragment on Progress {
    id
    level {
      id
      levelIndex
    }
    challenge {
      id
      challengeIndex
      type
      path
    }
  }
`;

export const CURRENT_USER_FRAGMENT = gql`
  fragment CurrentUserFragment on User {
    username
    fullName
    email
    avatar
    introduced
    signinProviders
  }
`;

export const CHALLENGE_FRAGMENT = gql`
  fragment ChallengeFragment on Challenge {
    id
    challengeIndex
    type
    path
    accomplished
    accessible
  }
`;
