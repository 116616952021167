import React from "react";
import PropTypes from "prop-types";

const EscapeOutside = ({ children, onEscapeOutside, elementRef }) => {
  React.useEffect(() => {
    const listener = (event) => {
      if (!elementRef.current || elementRef.current.contains(event.target)) {
        return;
      }
      onEscapeOutside();
    };

    document.addEventListener("click", listener);
    document.addEventListener("touchend", listener);

    return () => {
      document.removeEventListener("click", listener);
      document.removeEventListener("touchend", listener);
    };
  }, [elementRef, onEscapeOutside]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default EscapeOutside;

EscapeOutside.propTypes = {
  children: PropTypes.node.isRequired,
  onEscapeOutside: PropTypes.func.isRequired,
  elementRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};
