import React from "react";
import PropTypes from "prop-types";
import { ApolloProvider } from "@apollo/react-hooks";
import { ThemeProvider } from "styled-components";

import { AuthProvider } from "./auth-context";
import { client } from "../apollo-client";
import { theme } from "../theme";
import { ToastsProvider } from "../components/toast";
import { HideRouteContextProvider } from "./hide-route";

const AppProviders: React.FC = ({ children }) => (
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <ToastsProvider>
          <HideRouteContextProvider>{children}</HideRouteContextProvider>
        </ToastsProvider>
      </AuthProvider>
    </ThemeProvider>
  </ApolloProvider>
);

AppProviders.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppProviders;
