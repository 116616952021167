import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ReactComponent as DashboardIcon } from "remixicon/icons/System/dashboard-line.svg";
import { ReactComponent as SettingsIcon } from "remixicon/icons/System/settings-4-line.svg";
import { ReactComponent as SignOutIcon } from "remixicon/icons/System/logout-box-line.svg";
import { ReactComponent as ShieldIcon } from "remixicon/icons/System/shield-star-line.svg";

import {
  ProfileMenuStyle,
  MenuHeader,
  ProfileInfo,
  MenuItem,
  MenuItemPro,
  IconWrapper,
} from "./style";
import Avatar from "../avatar";
import { Divider } from "../style";
import { useAuth } from "../../context/auth-context";

const ProfileMenu = ({ user }) => {
  const { logout, loadingUser } = useAuth();

  return (
    <ProfileMenuStyle>
      <MenuHeader>
        <span>
          <Avatar src={user.avatar} name={user.fullName} size={48} />
        </span>
        <ProfileInfo>
          <h2>{user.fullName}</h2>
          <h3>{user.email}</h3>
        </ProfileInfo>
      </MenuHeader>

      <Link to="/upgrade" className="upgrade-link">
        <MenuItemPro>
          <IconWrapper>
            <ShieldIcon />
          </IconWrapper>
          <h3>become a pro</h3>
        </MenuItemPro>
      </Link>

      <Link to="/dashboard">
        <MenuItem>
          <IconWrapper>
            <DashboardIcon />
          </IconWrapper>
          <h3>dashboard</h3>
        </MenuItem>
      </Link>

      <Link to="/settings">
        <MenuItem>
          <IconWrapper>
            <SettingsIcon />
          </IconWrapper>
          <h3>settings</h3>
        </MenuItem>
      </Link>

      <Divider />
      <MenuItem onClick={() => (loadingUser ? null : logout())}>
        <IconWrapper>
          <SignOutIcon />
        </IconWrapper>
        <h3>sign out</h3>
      </MenuItem>
    </ProfileMenuStyle>
  );
};

ProfileMenu.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    fullName: PropTypes.string,
    avatar: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default ProfileMenu;
