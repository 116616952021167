import React from "react";
import PropTypes from "prop-types";
import {
  SpinnerWrapper,
  SpinnerStyle,
  FullWidthWrapper,
  CardSpinnerStyle,
} from "./style";

// check http://stackoverflow.com/a/18473154/3124288 for calculating arc path
const R = 45;
const SPINNER_TRACK = `m 50,50 m 0,-${R} a ${R},${R} 0 1 1 0,${
  R * 2
} a ${R},${R} 0 1 1 0,-${R * 2}`;

const PATH_LENGTH = 280;

const STROKE_WIDTH = 4;
const MIN_STROKE_WIDTH = 16;

const SIZE_SMALL = 20;
const SIZE_STANDARD = 35;
const SIZE_LARGE = 50;

const getViewBox = (strokeWidth) => {
  const radius = R + strokeWidth / 2;
  const viewBoxX = 50 - radius;
  const viewBoxWidth = radius * 2;
  return `${viewBoxX} ${viewBoxX} ${viewBoxWidth} ${viewBoxWidth}`;
};

const getSize = (size) => {
  if (typeof size === "string") {
    if (size === "small") {
      return SIZE_SMALL;
    }
    if (size === "large") {
      return SIZE_LARGE;
    }
    return SIZE_STANDARD;
  }
  return Math.max(SIZE_SMALL, size);
};

const Spinner = (props) => {
  const { value, size: s, color } = props;

  const size = getSize(s);

  // keep spinner track width consistent at all sizes (down to about 20px).
  const strokeWidth = Math.min(
    MIN_STROKE_WIDTH,
    (STROKE_WIDTH * SIZE_LARGE) / size,
  );

  const strokeOffset =
    PATH_LENGTH -
    PATH_LENGTH * (value == null ? 0.25 : Math.min(Math.max(value, 0), 1));

  return (
    <SpinnerWrapper>
      <SpinnerStyle color={color}>
        <svg
          width={size}
          height={size}
          strokeWidth={strokeWidth}
          viewBox={getViewBox(strokeWidth)}
        >
          <path className="spinner-track" d={SPINNER_TRACK} />
          <path
            className="spinner-head"
            d={SPINNER_TRACK}
            pathLength={PATH_LENGTH}
            strokeDasharray={`${PATH_LENGTH} ${PATH_LENGTH}`}
            strokeDashoffset={strokeOffset}
          />
        </svg>
      </SpinnerStyle>
    </SpinnerWrapper>
  );
};

export const FullWidthSpinner = (props) => (
  <FullWidthWrapper>
    <Spinner {...props} />
  </FullWidthWrapper>
);

export const CardSpinner = ({ alt, ...rest }) => (
  <CardSpinnerStyle>
    <Spinner {...rest} />
    <h2>{alt}</h2>
  </CardSpinnerStyle>
);

Spinner.defaultProps = {
  value: null,
  size: 35,
  color: "#395aed",
};

Spinner.propTypes = {
  value: PropTypes.number,
  color: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(["small", "normal", "large"]),
  ]),
};

CardSpinner.defaultProps = {
  alt: "Loading...",
};

CardSpinner.propTypes = {
  alt: PropTypes.string,
};

export default Spinner;
