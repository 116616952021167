import styled from "styled-components";

export const DialogHeader = styled.div`
  display: grid;
  grid-gap: 1rem;
  align-content: flex-start;
`;

export const Logo = styled.div`
  font-size: 1.3rem;
  font-weight: 900;
  text-transform: uppercase;
  color: #2c4dc6;
`;

export const Heading = styled.h1`
  font-size: 3rem;
  line-height: 1.2;
  letter-spacing: 0.022rem;
  font-weight: 700;
  color: rgb(25, 25, 59);
  margin-bottom: 1rem;
`;

export const Description = styled.p`
  margin-bottom: 2rem;
  color: rgb(25, 25, 59);
  line-height: 1.4;
  letter-spacing: 0.022rem;
`;

export const FormWrapper = styled.div`
  button {
    margin-top: 4rem;
  }
`;

export const DialogWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const DialogContainer = styled.div`
  background-color: white;
  margin: 1rem;
  border-radius: 6px;
  max-width: 30em;
`;
