import styled from "styled-components";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0rem;
  left: 0rem;
  width: 100%;
  height: 100%;
  z-index: 1400;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
  background-color: hsla(0, 0%, 0%, 0.3);
`;

export const ModalCard = styled.div`
  position: relative;
  padding: 1.2rem;
  margin: 3.5rem auto;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 28rem;
`;

export const RoundedIconButton = styled.button.attrs({ type: "button" })`
  width: 1.8rem;
  height: 1.8rem;
  padding: 0.1rem;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  color: #19193b;
  border: 1.5px solid transparent;
  border-radius: 3rem;
  cursor: pointer;
  background: none;
  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
`;
