import React from "react";
import { useApolloClient } from "@apollo/react-hooks";

import {
  useLogoutMutation,
  useCurrentUserQuery,
  CurrentUserQuery,
} from "../generated/apollo-hooks";

interface AuthContextValues {
  currentUser?: CurrentUserQuery["currentUser"];
  logout: () => Promise<void>;
  loadingUser: boolean;
  signingOut: boolean;
}

const AuthContext = React.createContext<AuthContextValues>(null!);

const AuthProvider: React.FC = (props) => {
  const apolloClient = useApolloClient();
  const [logoutMutation, { loading: signingOut }] = useLogoutMutation();
  const { data, loading: loadingUser } = useCurrentUserQuery();

  // if no currentUser returned it will be undefined
  const currentUser = React.useMemo(() => {
    return !loadingUser && data?.currentUser ? data.currentUser : null;
  }, [data, loadingUser]);

  // logout
  const logout = React.useCallback(async () => {
    try {
      const res = await logoutMutation();
      if (res.data && res.data.logout) {
        // clear apollo store
        await apolloClient.clearStore();
        // navigate to home page
        window.location.replace("/");
      }
    } catch (error) {
      console.log("logout Error 👀", error);
    }
  }, [logoutMutation, apolloClient]);

  if (loadingUser) return null;

  const context = {
    currentUser,
    logout,
    loadingUser,
    signingOut,
  };

  return <AuthContext.Provider value={context} {...props} />;
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  return context;
};

export { AuthProvider, useAuth };
