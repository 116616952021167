import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import RoutesWrapper from "../components/routes-wrapper";
import fallbackTo from "../helpers/fallback-to";
import Header from "../components/header";
import Footer from "../components/footer";
import AutoHidden from "../components/auto-hidden-route";
import { FullWidthSpinner } from "../components/spinner";
import ConfirmEmailAndUsername from "../components/confirm-email-username";

// lazy load pages
const Levels = React.lazy(() => import("../pages/levels"));
const Challenges = React.lazy(() => import("../pages/challenges"));
const Challenge = React.lazy(() => import("../pages/challenge"));
const Settings = React.lazy(() => import("../pages/settings"));
const Landing = React.lazy(() => import("../pages/landing"));
const Signup = React.lazy(() => import("../pages/signup"));
const Signin = React.lazy(() => import("../pages/signin"));
const ResetPassword = React.lazy(() => import("../pages/reset-password"));
const VerifyResetPassword = React.lazy(() =>
  import("../pages/verify-reset-password"),
);

// protect pages or fallback to a certain page
// when a user is not signed in or does not have enough permissions
const HomePage = fallbackTo(Levels, Landing);
const ChallengesPage = fallbackTo(Challenges, () => <Redirect to="/sign-in" />);
const ChallengePage = fallbackTo(Challenge, () => <Redirect to="/sign-in" />);
const SettingsPage = fallbackTo(Settings, () => <Redirect to="/" />);
const SignupPage = fallbackTo(() => <Redirect to="/" />, Signup);
const SigninPage = fallbackTo(() => <Redirect to="/" />, Signin);
const VerifyResetPasswordPage = fallbackTo(
  () => <Redirect to="/" />,
  VerifyResetPassword,
);

const Routes: React.FC = () => (
  <Router>
    <AutoHidden
      component={Header}
      onlyIn={["sign-up", "sign-in", "reset-password"]}
    />
    <ConfirmEmailAndUsername />
    <Suspense fallback={<FullWidthSpinner />}>
      <RoutesWrapper>
        <Switch>
          {/* all routes */}
          <Route path="/" exact component={HomePage} />
          <Route path="/level/:levelIndex" exact component={ChallengesPage} />
          <Route
            path="/challenge/:challengePathHash"
            exact
            component={ChallengePage}
          />
          <Route path="/settings" component={SettingsPage} />
          <Route path="/sign-up" component={SignupPage} />
          <Route path="/sign-in" component={SigninPage} />
          <Route path="/reset-password" exact component={ResetPassword} />
          <Route
            path="/reset-password/:resetToken"
            component={VerifyResetPasswordPage}
          />
          <Route component={() => <Redirect to="/" />} />
        </Switch>
      </RoutesWrapper>
    </Suspense>
    <AutoHidden
      component={Footer}
      onlyIn={["sign-up", "sign-in", "reset-password"]}
    />
  </Router>
);

export default Routes;
