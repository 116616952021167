/* eslint-disable react/forbid-prop-types */
import React from "react";
import Portal from "@reach/portal";
import PropTypes from "prop-types";

import { ToastManagerStyle } from "./style";
import Toast from "./toast";

const ToastsManager = (props) => {
  const { toasts, removeToast } = props;

  return (
    <Portal type="div">
      <ToastManagerStyle>
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            onClose={() => removeToast(toast.id)}
            {...toast}
          />
        ))}
      </ToastManagerStyle>
    </Portal>
  );
};

ToastsManager.propTypes = {
  /* Toasts array */
  toasts: PropTypes.array.isRequired,
  removeToast: PropTypes.func.isRequired,
};

export default ToastsManager;
