import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Json: any;
  Upload: any;
};



export type Query = {
  __typename?: 'Query';
  challengeByHash?: Maybe<Challenge>;
  collections: Array<Collection>;
  currentProgress: Progress;
  currentUser?: Maybe<User>;
  grammarLesson: GrammarLesson;
  letterLesson: LetterLesson;
  level?: Maybe<Level>;
  levels: Array<Level>;
  progressByChallengeHash?: Maybe<Progress>;
  quiz: Quiz;
  readingLesson: ReadingLesson;
  vocabularyLesson: VocabularyLesson;
};


export type QueryChallengeByHashArgs = {
  hash: Scalars['String'];
};


export type QueryCollectionsArgs = {
  orderBy?: Maybe<CollectionOrderByInput>;
};


export type QueryGrammarLessonArgs = {
  input: GrammarLessonWhereUniqueInput;
};


export type QueryLetterLessonArgs = {
  input: LetterLessonWhereUniqueInput;
};


export type QueryLevelArgs = {
  where: LevelWhereUniqueInput;
};


export type QueryLevelsArgs = {
  orderBy?: Maybe<LevelOrderByInput>;
};


export type QueryProgressByChallengeHashArgs = {
  challengeHash: Scalars['String'];
};


export type QueryQuizArgs = {
  input: QuizWhereUniqueInput;
};


export type QueryReadingLessonArgs = {
  input: ReadingLessonWhereUniqueInput;
};


export type QueryVocabularyLessonArgs = {
  input: VocabularyLessonWhereUniqueInput;
};

export type CollectionOrderByInput = 
  | 'collectionIndex_ASC'
  | 'collectionIndex_DESC';

export type LevelOrderByInput = 
  | 'levelIndex_ASC'
  | 'levelIndex_DESC';

export type Collection = {
  __typename?: 'Collection';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  collectionIndex: Scalars['Int'];
  levels: Array<Level>;
};


export type CollectionLevelsArgs = {
  orderBy?: Maybe<LevelOrderByInput>;
};

export type GrammarLessonWhereUniqueInput = {
  id: Scalars['ID'];
};

export type GrammarLesson = Challenge & {
  __typename?: 'GrammarLesson';
  id: Scalars['ID'];
  challengeIndex: Scalars['Int'];
  title: Scalars['String'];
  titleTranslation: Scalars['String'];
  path: Scalars['String'];
  type: ChallengeType;
  accomplished: Scalars['Boolean'];
  accessible: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  content: Scalars['Json'];
};

export type LetterLessonWhereUniqueInput = {
  id: Scalars['ID'];
};

export type LetterLesson = Challenge & {
  __typename?: 'LetterLesson';
  id: Scalars['ID'];
  challengeIndex: Scalars['Int'];
  title: Scalars['String'];
  titleTranslation: Scalars['String'];
  type: ChallengeType;
  path: Scalars['String'];
  accomplished: Scalars['Boolean'];
  accessible: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  content: Scalars['Json'];
};

export type LevelWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  levelIndex?: Maybe<Scalars['Int']>;
};

export type Level = {
  __typename?: 'Level';
  id: Scalars['ID'];
  levelIndex: Scalars['Int'];
  title: Scalars['String'];
  avatar: Scalars['String'];
  accessible: Scalars['Boolean'];
  completed: Scalars['Boolean'];
  isLastLevel: Scalars['Boolean'];
  totalMaterials: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  progress?: Maybe<Progress>;
  collection: Collection;
  challenges: Array<Challenge>;
};

export type ProgressToUpdateInput = {
  id: Scalars['ID'];
};

export type ChallengeToAccomplishInput = {
  id: Scalars['ID'];
  type: ChallengeType;
};

export type ChallengeToUnlockInput = {
  id: Scalars['ID'];
  type: ChallengeType;
};

export type LevelToAccomplishInput = {
  levelIndex: Scalars['Int'];
};

export type LevelToUnlockInput = {
  levelIndex: Scalars['Int'];
};

export type SaveUserProgressInput = {
  progressToUpdate?: Maybe<ProgressToUpdateInput>;
  challengeToAccomplish?: Maybe<ChallengeToAccomplishInput>;
  challengeToUnlock?: Maybe<ChallengeToUnlockInput>;
  levelToAccomplish?: Maybe<LevelToAccomplishInput>;
  levelToUnlock?: Maybe<LevelToUnlockInput>;
};

export type SavedProgressPayload = {
  __typename?: 'SavedProgressPayload';
  accomplishedLevel?: Maybe<Level>;
  accomplishedChallenge?: Maybe<Challenge>;
  unlockedLevel?: Maybe<Level>;
  unlockedChallenge?: Maybe<Challenge>;
};

export type Progress = {
  __typename?: 'Progress';
  id: Scalars['ID'];
  user: User;
  level: Level;
  challenge: Challenge;
  nextLevel?: Maybe<Level>;
  nextChallenge?: Maybe<Challenge>;
};


export type ProgressChallengeArgs = {
  challengeHash?: Maybe<Scalars['String']>;
};


export type ProgressNextChallengeArgs = {
  challengeHash?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  editUser: User;
  logout: Scalars['Boolean'];
  requestResetPassword: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  saveUserProgress: SavedProgressPayload;
  signin: Scalars['Boolean'];
  signup: Scalars['Boolean'];
};


export type MutationEditUserArgs = {
  input: EditUserInput;
};


export type MutationRequestResetPasswordArgs = {
  input: RequestResetPasswordInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationSaveUserProgressArgs = {
  input: SaveUserProgressInput;
};


export type MutationSigninArgs = {
  input: SignInInput;
};


export type MutationSignupArgs = {
  input: CreateUserInput;
};

export type QuizWhereUniqueInput = {
  id: Scalars['ID'];
};

export type Quiz = Challenge & {
  __typename?: 'Quiz';
  id: Scalars['ID'];
  challengeIndex: Scalars['Int'];
  title: Scalars['String'];
  path: Scalars['String'];
  type: ChallengeType;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  accomplished: Scalars['Boolean'];
  accessible: Scalars['Boolean'];
  content: Scalars['Json'];
};

export type ReadingLessonWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ReadingLesson = Challenge & {
  __typename?: 'ReadingLesson';
  id: Scalars['ID'];
  challengeIndex: Scalars['Int'];
  title: Scalars['String'];
  titleTranslation: Scalars['String'];
  path: Scalars['String'];
  type: ChallengeType;
  thumbnailUrl: Scalars['String'];
  estimatedTime: Scalars['String'];
  accomplished: Scalars['Boolean'];
  accessible: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  content: Scalars['Json'];
};




export type ChallengeType = 
  | 'LetterLesson'
  | 'VocabularyLesson'
  | 'GrammarLesson'
  | 'ReadingLesson'
  | 'Quiz';

export type LevelChallenge = LetterLesson | GrammarLesson | VocabularyLesson | ReadingLesson | Quiz;

export type Challenge = {
  id: Scalars['ID'];
  challengeIndex: Scalars['Int'];
  type: ChallengeType;
  path: Scalars['String'];
  title: Scalars['String'];
  accomplished: Scalars['Boolean'];
  accessible: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  content: Scalars['Json'];
};

export type SigninProviders = 
  | 'local'
  | 'facebook'
  | 'google';

export type CreateUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  fullName: Scalars['String'];
  username: Scalars['String'];
};

export type SignInInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type RequestResetPasswordInput = {
  email: Scalars['String'];
};

export type ResetPasswordInput = {
  resetToken: Scalars['String'];
  password: Scalars['String'];
};

export type EditUserInput = {
  fullName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  oldPassword?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
  introduced?: Maybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  fullName: Scalars['String'];
  username: Scalars['String'];
  email: Scalars['String'];
  avatar: Scalars['String'];
  introduced: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  signinProviders: Array<SigninProviders>;
};

export type VocabularyLessonWhereUniqueInput = {
  id: Scalars['ID'];
};

export type VocabularyLesson = Challenge & {
  __typename?: 'VocabularyLesson';
  id: Scalars['ID'];
  challengeIndex: Scalars['Int'];
  title: Scalars['String'];
  titleTranslation: Scalars['String'];
  path: Scalars['String'];
  type: ChallengeType;
  accomplished: Scalars['Boolean'];
  accessible: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  content: Scalars['Json'];
};

export type LevelInfoFragment = (
  { __typename?: 'Level' }
  & Pick<Level, 'id' | 'title' | 'levelIndex' | 'avatar' | 'completed' | 'accessible' | 'isLastLevel' | 'totalMaterials'>
);

export type ProgressInLevelFragmentFragment = (
  { __typename?: 'Progress' }
  & Pick<Progress, 'id'>
  & { level: (
    { __typename?: 'Level' }
    & Pick<Level, 'id' | 'levelIndex'>
  ), challenge: (
    { __typename?: 'GrammarLesson' }
    & Pick<GrammarLesson, 'id' | 'challengeIndex' | 'type' | 'path'>
  ) | (
    { __typename?: 'LetterLesson' }
    & Pick<LetterLesson, 'id' | 'challengeIndex' | 'type' | 'path'>
  ) | (
    { __typename?: 'Quiz' }
    & Pick<Quiz, 'id' | 'challengeIndex' | 'type' | 'path'>
  ) | (
    { __typename?: 'ReadingLesson' }
    & Pick<ReadingLesson, 'id' | 'challengeIndex' | 'type' | 'path'>
  ) | (
    { __typename?: 'VocabularyLesson' }
    & Pick<VocabularyLesson, 'id' | 'challengeIndex' | 'type' | 'path'>
  ) }
);

export type CurrentUserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'username' | 'fullName' | 'email' | 'avatar' | 'introduced' | 'signinProviders'>
);

type ChallengeFragmentGrammarLessonFragment = (
  { __typename?: 'GrammarLesson' }
  & Pick<GrammarLesson, 'id' | 'challengeIndex' | 'type' | 'path' | 'accomplished' | 'accessible'>
);

type ChallengeFragmentLetterLessonFragment = (
  { __typename?: 'LetterLesson' }
  & Pick<LetterLesson, 'id' | 'challengeIndex' | 'type' | 'path' | 'accomplished' | 'accessible'>
);

type ChallengeFragmentQuizFragment = (
  { __typename?: 'Quiz' }
  & Pick<Quiz, 'id' | 'challengeIndex' | 'type' | 'path' | 'accomplished' | 'accessible'>
);

type ChallengeFragmentReadingLessonFragment = (
  { __typename?: 'ReadingLesson' }
  & Pick<ReadingLesson, 'id' | 'challengeIndex' | 'type' | 'path' | 'accomplished' | 'accessible'>
);

type ChallengeFragmentVocabularyLessonFragment = (
  { __typename?: 'VocabularyLesson' }
  & Pick<VocabularyLesson, 'id' | 'challengeIndex' | 'type' | 'path' | 'accomplished' | 'accessible'>
);

export type ChallengeFragmentFragment = ChallengeFragmentGrammarLessonFragment | ChallengeFragmentLetterLessonFragment | ChallengeFragmentQuizFragment | ChallengeFragmentReadingLessonFragment | ChallengeFragmentVocabularyLessonFragment;

export type SaveUserProgressMutationVariables = Exact<{
  input: SaveUserProgressInput;
}>;


export type SaveUserProgressMutation = (
  { __typename?: 'Mutation' }
  & { saveUserProgress: (
    { __typename?: 'SavedProgressPayload' }
    & { accomplishedLevel?: Maybe<(
      { __typename?: 'Level' }
      & Pick<Level, 'id' | 'levelIndex'>
    )>, unlockedLevel?: Maybe<(
      { __typename?: 'Level' }
      & Pick<Level, 'id' | 'levelIndex'>
    )>, accomplishedChallenge?: Maybe<(
      { __typename?: 'GrammarLesson' }
      & Pick<GrammarLesson, 'id' | 'challengeIndex' | 'type' | 'path'>
    ) | (
      { __typename?: 'LetterLesson' }
      & Pick<LetterLesson, 'id' | 'challengeIndex' | 'type' | 'path'>
    ) | (
      { __typename?: 'Quiz' }
      & Pick<Quiz, 'id' | 'challengeIndex' | 'type' | 'path'>
    ) | (
      { __typename?: 'ReadingLesson' }
      & Pick<ReadingLesson, 'id' | 'challengeIndex' | 'type' | 'path'>
    ) | (
      { __typename?: 'VocabularyLesson' }
      & Pick<VocabularyLesson, 'id' | 'challengeIndex' | 'type' | 'path'>
    )>, unlockedChallenge?: Maybe<(
      { __typename?: 'GrammarLesson' }
      & Pick<GrammarLesson, 'id' | 'challengeIndex' | 'type' | 'path'>
    ) | (
      { __typename?: 'LetterLesson' }
      & Pick<LetterLesson, 'id' | 'challengeIndex' | 'type' | 'path'>
    ) | (
      { __typename?: 'Quiz' }
      & Pick<Quiz, 'id' | 'challengeIndex' | 'type' | 'path'>
    ) | (
      { __typename?: 'ReadingLesson' }
      & Pick<ReadingLesson, 'id' | 'challengeIndex' | 'type' | 'path'>
    ) | (
      { __typename?: 'VocabularyLesson' }
      & Pick<VocabularyLesson, 'id' | 'challengeIndex' | 'type' | 'path'>
    )> }
  ) }
);

export type SignupMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type SignupMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'signup'>
);

export type SigninMutationVariables = Exact<{
  input: SignInInput;
}>;


export type SigninMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'signin'>
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type RequestResetPasswordMutationVariables = Exact<{
  input: RequestResetPasswordInput;
}>;


export type RequestResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestResetPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type EditUserMutationVariables = Exact<{
  input: EditUserInput;
}>;


export type EditUserMutation = (
  { __typename?: 'Mutation' }
  & { editUser: (
    { __typename?: 'User' }
    & CurrentUserFragmentFragment
  ) }
);

export type UpdateUserAvatarMutationVariables = Exact<{
  input: EditUserInput;
}>;


export type UpdateUserAvatarMutation = (
  { __typename?: 'Mutation' }
  & { editUser: (
    { __typename?: 'User' }
    & Pick<User, 'username' | 'avatar'>
  ) }
);

export type ChallengeByHashQueryVariables = Exact<{
  challengePathHash: Scalars['String'];
}>;


export type ChallengeByHashQuery = (
  { __typename?: 'Query' }
  & { challengeByHash?: Maybe<(
    { __typename?: 'GrammarLesson' }
    & Pick<GrammarLesson, 'title' | 'titleTranslation' | 'content'>
    & ChallengeFragmentGrammarLessonFragment
  ) | (
    { __typename?: 'LetterLesson' }
    & Pick<LetterLesson, 'title' | 'titleTranslation' | 'content'>
    & ChallengeFragmentLetterLessonFragment
  ) | (
    { __typename?: 'Quiz' }
    & Pick<Quiz, 'title' | 'content'>
    & ChallengeFragmentQuizFragment
  ) | (
    { __typename?: 'ReadingLesson' }
    & Pick<ReadingLesson, 'title' | 'titleTranslation' | 'thumbnailUrl' | 'estimatedTime' | 'content'>
    & ChallengeFragmentReadingLessonFragment
  ) | (
    { __typename?: 'VocabularyLesson' }
    & Pick<VocabularyLesson, 'title' | 'titleTranslation' | 'content'>
    & ChallengeFragmentVocabularyLessonFragment
  )> }
);

export type CollectionsQueryVariables = Exact<{
  collectionsOrderBy?: Maybe<CollectionOrderByInput>;
  LevelsOrderBy?: Maybe<LevelOrderByInput>;
}>;


export type CollectionsQuery = (
  { __typename?: 'Query' }
  & { collections: Array<(
    { __typename?: 'Collection' }
    & Pick<Collection, 'id' | 'title' | 'collectionIndex' | 'description'>
    & { levels: Array<(
      { __typename?: 'Level' }
      & { collection: (
        { __typename?: 'Collection' }
        & Pick<Collection, 'id' | 'title'>
      ), progress?: Maybe<(
        { __typename?: 'Progress' }
        & ProgressInLevelFragmentFragment
      )> }
      & LevelInfoFragment
    )> }
  )> }
);

export type LevelQueryVariables = Exact<{
  where: LevelWhereUniqueInput;
}>;


export type LevelQuery = (
  { __typename?: 'Query' }
  & { level?: Maybe<(
    { __typename?: 'Level' }
    & { challenges: Array<(
      { __typename?: 'GrammarLesson' }
      & Pick<GrammarLesson, 'title'>
      & ChallengeFragmentGrammarLessonFragment
    ) | (
      { __typename?: 'LetterLesson' }
      & Pick<LetterLesson, 'title'>
      & ChallengeFragmentLetterLessonFragment
    ) | (
      { __typename?: 'Quiz' }
      & ChallengeFragmentQuizFragment
    ) | (
      { __typename?: 'ReadingLesson' }
      & ChallengeFragmentReadingLessonFragment
    ) | (
      { __typename?: 'VocabularyLesson' }
      & Pick<VocabularyLesson, 'title'>
      & ChallengeFragmentVocabularyLessonFragment
    )>, progress?: Maybe<(
      { __typename?: 'Progress' }
      & ProgressInLevelFragmentFragment
    )> }
    & LevelInfoFragment
  )> }
);

export type CurrentUseProgressQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUseProgressQuery = (
  { __typename?: 'Query' }
  & { currentProgress: (
    { __typename?: 'Progress' }
    & Pick<Progress, 'id'>
    & { level: (
      { __typename?: 'Level' }
      & { collection: (
        { __typename?: 'Collection' }
        & Pick<Collection, 'id' | 'title'>
      ) }
      & LevelInfoFragment
    ), challenge: (
      { __typename?: 'GrammarLesson' }
      & ChallengeFragmentGrammarLessonFragment
    ) | (
      { __typename?: 'LetterLesson' }
      & ChallengeFragmentLetterLessonFragment
    ) | (
      { __typename?: 'Quiz' }
      & ChallengeFragmentQuizFragment
    ) | (
      { __typename?: 'ReadingLesson' }
      & ChallengeFragmentReadingLessonFragment
    ) | (
      { __typename?: 'VocabularyLesson' }
      & ChallengeFragmentVocabularyLessonFragment
    ) }
  ) }
);

export type ProgressByChallengeHashQueryVariables = Exact<{
  hash: Scalars['String'];
}>;


export type ProgressByChallengeHashQuery = (
  { __typename?: 'Query' }
  & { progressByChallengeHash?: Maybe<(
    { __typename?: 'Progress' }
    & Pick<Progress, 'id'>
    & { level: (
      { __typename?: 'Level' }
      & { challenges: Array<(
        { __typename?: 'GrammarLesson' }
        & Pick<GrammarLesson, 'title'>
        & ChallengeFragmentGrammarLessonFragment
      ) | (
        { __typename?: 'LetterLesson' }
        & Pick<LetterLesson, 'title'>
        & ChallengeFragmentLetterLessonFragment
      ) | (
        { __typename?: 'Quiz' }
        & ChallengeFragmentQuizFragment
      ) | (
        { __typename?: 'ReadingLesson' }
        & ChallengeFragmentReadingLessonFragment
      ) | (
        { __typename?: 'VocabularyLesson' }
        & Pick<VocabularyLesson, 'title'>
        & ChallengeFragmentVocabularyLessonFragment
      )> }
      & LevelInfoFragment
    ), nextLevel?: Maybe<(
      { __typename?: 'Level' }
      & LevelInfoFragment
    )>, challenge: (
      { __typename?: 'GrammarLesson' }
      & ChallengeFragmentGrammarLessonFragment
    ) | (
      { __typename?: 'LetterLesson' }
      & ChallengeFragmentLetterLessonFragment
    ) | (
      { __typename?: 'Quiz' }
      & ChallengeFragmentQuizFragment
    ) | (
      { __typename?: 'ReadingLesson' }
      & ChallengeFragmentReadingLessonFragment
    ) | (
      { __typename?: 'VocabularyLesson' }
      & ChallengeFragmentVocabularyLessonFragment
    ), nextChallenge?: Maybe<(
      { __typename?: 'GrammarLesson' }
      & ChallengeFragmentGrammarLessonFragment
    ) | (
      { __typename?: 'LetterLesson' }
      & ChallengeFragmentLetterLessonFragment
    ) | (
      { __typename?: 'Quiz' }
      & ChallengeFragmentQuizFragment
    ) | (
      { __typename?: 'ReadingLesson' }
      & ChallengeFragmentReadingLessonFragment
    ) | (
      { __typename?: 'VocabularyLesson' }
      & ChallengeFragmentVocabularyLessonFragment
    )> }
  )> }
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'User' }
    & CurrentUserFragmentFragment
  )> }
);

export const LevelInfoFragmentDoc = gql`
    fragment LevelInfo on Level {
  id
  title
  levelIndex
  avatar
  completed
  accessible
  isLastLevel
  totalMaterials
}
    `;
export const ProgressInLevelFragmentFragmentDoc = gql`
    fragment ProgressInLevelFragment on Progress {
  id
  level {
    id
    levelIndex
  }
  challenge {
    id
    challengeIndex
    type
    path
  }
}
    `;
export const CurrentUserFragmentFragmentDoc = gql`
    fragment CurrentUserFragment on User {
  username
  fullName
  email
  avatar
  introduced
  signinProviders
}
    `;
export const ChallengeFragmentFragmentDoc = gql`
    fragment ChallengeFragment on Challenge {
  id
  challengeIndex
  type
  path
  accomplished
  accessible
}
    `;
export const SaveUserProgressDocument = gql`
    mutation SAVE_USER_PROGRESS($input: SaveUserProgressInput!) {
  saveUserProgress(input: $input) {
    accomplishedLevel {
      id
      levelIndex
    }
    unlockedLevel {
      id
      levelIndex
    }
    accomplishedChallenge {
      id
      challengeIndex
      type
      path
    }
    unlockedChallenge {
      id
      challengeIndex
      type
      path
    }
  }
}
    `;
export type SaveUserProgressMutationFn = ApolloReactCommon.MutationFunction<SaveUserProgressMutation, SaveUserProgressMutationVariables>;

/**
 * __useSaveUserProgressMutation__
 *
 * To run a mutation, you first call `useSaveUserProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserProgressMutation, { data, loading, error }] = useSaveUserProgressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUserProgressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveUserProgressMutation, SaveUserProgressMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveUserProgressMutation, SaveUserProgressMutationVariables>(SaveUserProgressDocument, baseOptions);
      }
export type SaveUserProgressMutationHookResult = ReturnType<typeof useSaveUserProgressMutation>;
export type SaveUserProgressMutationResult = ApolloReactCommon.MutationResult<SaveUserProgressMutation>;
export type SaveUserProgressMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveUserProgressMutation, SaveUserProgressMutationVariables>;
export const SignupDocument = gql`
    mutation SIGNUP($input: CreateUserInput!) {
  signup(input: $input)
}
    `;
export type SignupMutationFn = ApolloReactCommon.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        return ApolloReactHooks.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, baseOptions);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = ApolloReactCommon.MutationResult<SignupMutation>;
export type SignupMutationOptions = ApolloReactCommon.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const SigninDocument = gql`
    mutation SIGNIN($input: SignInInput!) {
  signin(input: $input)
}
    `;
export type SigninMutationFn = ApolloReactCommon.MutationFunction<SigninMutation, SigninMutationVariables>;

/**
 * __useSigninMutation__
 *
 * To run a mutation, you first call `useSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinMutation, { data, loading, error }] = useSigninMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSigninMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SigninMutation, SigninMutationVariables>) {
        return ApolloReactHooks.useMutation<SigninMutation, SigninMutationVariables>(SigninDocument, baseOptions);
      }
export type SigninMutationHookResult = ReturnType<typeof useSigninMutation>;
export type SigninMutationResult = ApolloReactCommon.MutationResult<SigninMutation>;
export type SigninMutationOptions = ApolloReactCommon.BaseMutationOptions<SigninMutation, SigninMutationVariables>;
export const LogoutDocument = gql`
    mutation LOGOUT {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RequestResetPasswordDocument = gql`
    mutation REQUEST_RESET_PASSWORD($input: RequestResetPasswordInput!) {
  requestResetPassword(input: $input)
}
    `;
export type RequestResetPasswordMutationFn = ApolloReactCommon.MutationFunction<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument, baseOptions);
      }
export type RequestResetPasswordMutationHookResult = ReturnType<typeof useRequestResetPasswordMutation>;
export type RequestResetPasswordMutationResult = ApolloReactCommon.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation RESET_PASSWORD($input: ResetPasswordInput!) {
  resetPassword(input: $input)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const EditUserDocument = gql`
    mutation EDIT_USER($input: EditUserInput!) {
  editUser(input: $input) {
    ...CurrentUserFragment
  }
}
    ${CurrentUserFragmentFragmentDoc}`;
export type EditUserMutationFn = ApolloReactCommon.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        return ApolloReactHooks.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, baseOptions);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = ApolloReactCommon.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = ApolloReactCommon.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const UpdateUserAvatarDocument = gql`
    mutation UPDATE_USER_AVATAR($input: EditUserInput!) {
  editUser(input: $input) {
    username
    avatar
  }
}
    `;
export type UpdateUserAvatarMutationFn = ApolloReactCommon.MutationFunction<UpdateUserAvatarMutation, UpdateUserAvatarMutationVariables>;

/**
 * __useUpdateUserAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateUserAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAvatarMutation, { data, loading, error }] = useUpdateUserAvatarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserAvatarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserAvatarMutation, UpdateUserAvatarMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserAvatarMutation, UpdateUserAvatarMutationVariables>(UpdateUserAvatarDocument, baseOptions);
      }
export type UpdateUserAvatarMutationHookResult = ReturnType<typeof useUpdateUserAvatarMutation>;
export type UpdateUserAvatarMutationResult = ApolloReactCommon.MutationResult<UpdateUserAvatarMutation>;
export type UpdateUserAvatarMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserAvatarMutation, UpdateUserAvatarMutationVariables>;
export const ChallengeByHashDocument = gql`
    query CHALLENGE_BY_HASH($challengePathHash: String!) {
  challengeByHash(hash: $challengePathHash) {
    ...ChallengeFragment
    content
    ... on LetterLesson {
      title
      titleTranslation
    }
    ... on VocabularyLesson {
      title
      titleTranslation
    }
    ... on GrammarLesson {
      title
      titleTranslation
    }
    ... on Quiz {
      title
    }
    ... on ReadingLesson {
      title
      titleTranslation
      thumbnailUrl
      estimatedTime
    }
  }
}
    ${ChallengeFragmentFragmentDoc}`;

/**
 * __useChallengeByHashQuery__
 *
 * To run a query within a React component, call `useChallengeByHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useChallengeByHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChallengeByHashQuery({
 *   variables: {
 *      challengePathHash: // value for 'challengePathHash'
 *   },
 * });
 */
export function useChallengeByHashQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChallengeByHashQuery, ChallengeByHashQueryVariables>) {
        return ApolloReactHooks.useQuery<ChallengeByHashQuery, ChallengeByHashQueryVariables>(ChallengeByHashDocument, baseOptions);
      }
export function useChallengeByHashLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChallengeByHashQuery, ChallengeByHashQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChallengeByHashQuery, ChallengeByHashQueryVariables>(ChallengeByHashDocument, baseOptions);
        }
export type ChallengeByHashQueryHookResult = ReturnType<typeof useChallengeByHashQuery>;
export type ChallengeByHashLazyQueryHookResult = ReturnType<typeof useChallengeByHashLazyQuery>;
export type ChallengeByHashQueryResult = ApolloReactCommon.QueryResult<ChallengeByHashQuery, ChallengeByHashQueryVariables>;
export const CollectionsDocument = gql`
    query COLLECTIONS($collectionsOrderBy: CollectionOrderByInput = collectionIndex_ASC, $LevelsOrderBy: LevelOrderByInput = levelIndex_ASC) {
  collections(orderBy: $collectionsOrderBy) {
    id
    title
    collectionIndex
    description
    levels(orderBy: $LevelsOrderBy) {
      ...LevelInfo
      collection {
        id
        title
      }
      progress {
        ...ProgressInLevelFragment
      }
    }
  }
}
    ${LevelInfoFragmentDoc}
${ProgressInLevelFragmentFragmentDoc}`;

/**
 * __useCollectionsQuery__
 *
 * To run a query within a React component, call `useCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionsQuery({
 *   variables: {
 *      collectionsOrderBy: // value for 'collectionsOrderBy'
 *      LevelsOrderBy: // value for 'LevelsOrderBy'
 *   },
 * });
 */
export function useCollectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CollectionsQuery, CollectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, baseOptions);
      }
export function useCollectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CollectionsQuery, CollectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CollectionsQuery, CollectionsQueryVariables>(CollectionsDocument, baseOptions);
        }
export type CollectionsQueryHookResult = ReturnType<typeof useCollectionsQuery>;
export type CollectionsLazyQueryHookResult = ReturnType<typeof useCollectionsLazyQuery>;
export type CollectionsQueryResult = ApolloReactCommon.QueryResult<CollectionsQuery, CollectionsQueryVariables>;
export const LevelDocument = gql`
    query LEVEL($where: LevelWhereUniqueInput!) {
  level(where: $where) {
    ...LevelInfo
    challenges {
      ...ChallengeFragment
      ... on LetterLesson {
        title
      }
      ... on VocabularyLesson {
        title
      }
      ... on GrammarLesson {
        title
      }
    }
    progress {
      ...ProgressInLevelFragment
    }
  }
}
    ${LevelInfoFragmentDoc}
${ChallengeFragmentFragmentDoc}
${ProgressInLevelFragmentFragmentDoc}`;

/**
 * __useLevelQuery__
 *
 * To run a query within a React component, call `useLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLevelQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLevelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LevelQuery, LevelQueryVariables>) {
        return ApolloReactHooks.useQuery<LevelQuery, LevelQueryVariables>(LevelDocument, baseOptions);
      }
export function useLevelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LevelQuery, LevelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LevelQuery, LevelQueryVariables>(LevelDocument, baseOptions);
        }
export type LevelQueryHookResult = ReturnType<typeof useLevelQuery>;
export type LevelLazyQueryHookResult = ReturnType<typeof useLevelLazyQuery>;
export type LevelQueryResult = ApolloReactCommon.QueryResult<LevelQuery, LevelQueryVariables>;
export const CurrentUseProgressDocument = gql`
    query CURRENT_USE_PROGRESS {
  currentProgress {
    id
    level {
      ...LevelInfo
      collection {
        id
        title
      }
    }
    challenge {
      ...ChallengeFragment
    }
  }
}
    ${LevelInfoFragmentDoc}
${ChallengeFragmentFragmentDoc}`;

/**
 * __useCurrentUseProgressQuery__
 *
 * To run a query within a React component, call `useCurrentUseProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUseProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUseProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUseProgressQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUseProgressQuery, CurrentUseProgressQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentUseProgressQuery, CurrentUseProgressQueryVariables>(CurrentUseProgressDocument, baseOptions);
      }
export function useCurrentUseProgressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUseProgressQuery, CurrentUseProgressQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentUseProgressQuery, CurrentUseProgressQueryVariables>(CurrentUseProgressDocument, baseOptions);
        }
export type CurrentUseProgressQueryHookResult = ReturnType<typeof useCurrentUseProgressQuery>;
export type CurrentUseProgressLazyQueryHookResult = ReturnType<typeof useCurrentUseProgressLazyQuery>;
export type CurrentUseProgressQueryResult = ApolloReactCommon.QueryResult<CurrentUseProgressQuery, CurrentUseProgressQueryVariables>;
export const ProgressByChallengeHashDocument = gql`
    query PROGRESS_BY_CHALLENGE_HASH($hash: String!) {
  progressByChallengeHash(challengeHash: $hash) {
    id
    level {
      ...LevelInfo
      challenges {
        ...ChallengeFragment
        ... on LetterLesson {
          title
        }
        ... on VocabularyLesson {
          title
        }
        ... on GrammarLesson {
          title
        }
      }
    }
    nextLevel {
      ...LevelInfo
    }
    challenge(challengeHash: $hash) {
      ...ChallengeFragment
    }
    nextChallenge(challengeHash: $hash) {
      ...ChallengeFragment
    }
  }
}
    ${LevelInfoFragmentDoc}
${ChallengeFragmentFragmentDoc}`;

/**
 * __useProgressByChallengeHashQuery__
 *
 * To run a query within a React component, call `useProgressByChallengeHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgressByChallengeHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgressByChallengeHashQuery({
 *   variables: {
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useProgressByChallengeHashQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProgressByChallengeHashQuery, ProgressByChallengeHashQueryVariables>) {
        return ApolloReactHooks.useQuery<ProgressByChallengeHashQuery, ProgressByChallengeHashQueryVariables>(ProgressByChallengeHashDocument, baseOptions);
      }
export function useProgressByChallengeHashLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProgressByChallengeHashQuery, ProgressByChallengeHashQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProgressByChallengeHashQuery, ProgressByChallengeHashQueryVariables>(ProgressByChallengeHashDocument, baseOptions);
        }
export type ProgressByChallengeHashQueryHookResult = ReturnType<typeof useProgressByChallengeHashQuery>;
export type ProgressByChallengeHashLazyQueryHookResult = ReturnType<typeof useProgressByChallengeHashLazyQuery>;
export type ProgressByChallengeHashQueryResult = ApolloReactCommon.QueryResult<ProgressByChallengeHashQuery, ProgressByChallengeHashQueryVariables>;
export const CurrentUserDocument = gql`
    query CURRENT_USER {
  currentUser {
    ...CurrentUserFragment
  }
}
    ${CurrentUserFragmentFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;