import * as yup from "yup";

export const createPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .min(6, "Password must be at least 6 characters.")
    .required("Please enter your new secure password."),
  confirmPassword: yup
    .string()
    .test("match-password", "Your password didn't match.", function test(
      value,
    ) {
      return this.parent.newPassword === value;
    }),
});

export const updatePasswordSchema = yup
  .object()
  .shape({
    oldPassword: yup
      .string()
      .required("Your old password is required. Please enter it."),
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  .concat<any>(createPasswordSchema);

export const emailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email.")
    .required("Your email is required."),
});

export const fullNameSchema = yup.object().shape({
  fullName: yup.string().required("Please enter your full-name."),
});

export const profileSchema = yup
  .object()
  .shape({
    username: yup
      .string()
      .matches(
        /^[\w-]*$/,
        "Only letters, numbers, dashes, and underscores are allowed.",
      )
      .min(6, "Username is too short")
      .max(20, "Username must be between 3 and 20 characters.")
      .required("Please enter a valid username."),
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  .concat<any>(emailSchema);
