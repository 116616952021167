import React from "react";
import { RemoveScroll } from "react-remove-scroll";
import Portal from "@reach/portal";

import { ReactComponent as CloseIcon } from "remixicon/icons/System/close-line.svg";
import EscapeOutside from "../escape-outside";
import { ModalContainer, ModalCard, RoundedIconButton } from "./style";

interface ModalProps {
  onClose?: () => void;
  open: boolean;
  isClosable?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  children,
  onClose = () => {},
  open,
  isClosable = true,
  ...rest
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  return open ? (
    <Portal type="div">
      <RemoveScroll>
        <ModalContainer>
          <EscapeOutside
            onEscapeOutside={() => (isClosable ? onClose() : null)}
            elementRef={ref}
          >
            <ModalCard ref={ref} {...rest}>
              {isClosable && (
                <RoundedIconButton onClick={onClose}>
                  <CloseIcon />
                </RoundedIconButton>
              )}
              {children}
            </ModalCard>
          </EscapeOutside>
        </ModalContainer>
      </RemoveScroll>
    </Portal>
  ) : null;
};

export default Modal;
