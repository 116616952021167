import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as CheckIcon } from "remixicon/icons/System/checkbox-circle-fill.svg";
import { ReactComponent as CloseIcon } from "remixicon/icons/System/close-line.svg";
import { ReactComponent as ErrorIcon } from "remixicon/icons/System/error-warning-fill.svg";

import {
  ToastBaseStyle,
  Content,
  Title,
  Description,
  IconButton,
} from "./style";

const statuses = {
  success: { icon: CheckIcon, color: "#38a169" },
  error: { icon: ErrorIcon, color: "#e53e3e" },
};

const Icon = ({ status }) => {
  const C = statuses[status].icon;
  return <C />;
};

const Toast = ({ title, description, status, duration, onClose, ...rest }) => {
  const timeoutId = React.useRef(null);
  const durationTimer = React.useRef(duration);
  const callback = React.useRef();
  callback.current = onClose;

  React.useEffect(() => {
    callback.current = onClose;
  }, [onClose]);

  React.useEffect(() => {
    startTimer();
    return () => {
      clearTimeOut();
    };
  }, []);

  // start ticking
  const startTimer = () => {
    if (!durationTimer.current) return;
    timeoutId.current = setTimeout(() => {
      callback.current();
    }, durationTimer.current * 1000);
  };

  // clear timeout
  const clearTimeOut = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  };

  // pause on hover
  const pause = () => {
    clearTimeOut();
  };

  // resume on mouse leave
  const play = () => {
    startTimer();
  };

  return (
    <ToastBaseStyle
      onMouseEnter={pause}
      onMouseLeave={play}
      color={statuses[status].color}
      {...rest}
    >
      <Icon status={status} />
      <Content>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </Content>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </ToastBaseStyle>
  );
};

Toast.defaultProps = {
  duration: 3,
  description: "",
};

Icon.propTypes = {
  status: PropTypes.oneOf(["success", "error"]).isRequired,
};

Toast.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  status: PropTypes.oneOf(["success", "error"]).isRequired,
  /* Duration in seconds */
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.any]),
  onClose: PropTypes.func.isRequired,
};

export default React.memo(Toast);
