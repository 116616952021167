import styled from "styled-components";

import { Divider, FOR_MEDIA_PHONE_ONLY } from "../style";

export const ProfileStyle = styled.div`
  grid-area: profile;
  > div {
    display: flex;
  }
`;

export const ProfileMenuStyle = styled.div`
  display: grid;
  grid-row-gap: 0.4rem;
  background-color: white;
  color: #19193b;
  width: 240px;
  padding-bottom: 0.5rem;
  border-radius: 6px;
  ${Divider} {
    background-color: #cfcfcf;
  }
  .upgrade-link {
    margin: 1rem 0rem 0.5rem 0rem;
  }
`;

export const MenuHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
  justify-items: flex-start;
  align-items: center;
  padding: 1.5rem;
  background-color: #f1f5fd;
  > span {
    display: flex;
    box-shadow: 0px 10px 20px hsla(0, 0%, 0%, 0.2),
      0px 3px 6px hsla(0, 0%, 0%, 0.1);
    border-radius: 100%;
  }
`;

export const ProfileInfo = styled.div`
  width: 100%;
  color: #19193b;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  h2 {
    font-weight: 900;
    font-size: 1rem;
    line-height: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-bottom: 0.2rem;
  }
  h3 {
    font-size: 0.65rem;
    letter-spacing: 0.027rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const UserWidget = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 9rem;
  padding: 0.2rem;
  border-radius: 4rem;
  background-color: rgba(85, 124, 243, 0.08);
  backdrop-filter: blur(6px);
  cursor: pointer;
  user-select: none;
`;

export const ProfileName = styled.span`
  display: block;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.022rem;
  color: #19193b;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.5rem;
  @media only screen and (max-width: ${FOR_MEDIA_PHONE_ONLY}px) {
    display: none;
  }
`;

export const IconWrapper = styled.div`
  width: 48px;
  height: 1.5rem;
  color: inherit;
  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
`;

export const MenuItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
  justify-items: flex-start;
  align-items: center;
  padding: 0.8rem 1.5rem;
  background-color: white;
  color: #19193b;
  cursor: pointer;
  &:hover {
    background-color: #f1f5fd;
  }
  h3 {
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 1.4;
    text-transform: capitalize;
  }
`;

export const MenuItemPro = styled(MenuItem)`
  color: white;
  background-color: #395aed;
  &:hover {
    background-color: #395aed;
  }
`;
