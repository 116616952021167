import styled from "styled-components";

import { GRID_GAP } from "../style";

export const AppWrapperStyle = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    "navbar"
    "main"
    "footer";
  grid-gap: ${GRID_GAP}rem;
  justify-items: center;
  align-items: flex-start;
  background-color: #f0f3fc;
`;
