import React from "react";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { MutationFunctionOptions, ExecutionResult } from "@apollo/react-common";

import Input from "../input";
import Button from "../buttons";
import {
  DialogHeader,
  Logo,
  Heading,
  Description,
  FormWrapper,
  DialogWrapper,
  DialogContainer,
} from "./style";
import { profileSchema } from "../../validation/edit-user-schema-validation";
import {
  EditUserMutation,
  EditUserMutationVariables,
  CurrentUserFragmentFragment,
} from "../../generated/apollo-hooks";
import formatErrorsForFormik from "../../helpers/format-graphql-errors-for-formik";

interface IntroduceUserDialog {
  currentUser: CurrentUserFragmentFragment;
  editUser: (
    options?: MutationFunctionOptions<
      EditUserMutation,
      EditUserMutationVariables
    >,
  ) => Promise<ExecutionResult<EditUserMutation>>;
}

interface Values {
  username: string;
  email: string;
}

const IntroduceUserDialog: React.FC<IntroduceUserDialog> = ({
  editUser,
  currentUser,
}) => {
  const handleSubmit = async (
    values: Values,
    { setSubmitting, setErrors }: FormikHelpers<Values>,
  ) => {
    try {
      await editUser({
        variables: {
          input: { ...values, introduced: true },
        },
      });
    } catch ({ graphQLErrors }) {
      setSubmitting(false);
      setErrors(formatErrorsForFormik(graphQLErrors[0].message));
    }
  };

  return (
    <DialogWrapper>
      <DialogContainer>
        <DialogHeader>
          <Logo>Natanada</Logo>
          <Heading>Welcome to Natanada.</Heading>
          <Description>
            Verify your username and email to finish setting up your account.
          </Description>
        </DialogHeader>
        <FormWrapper>
          <Formik
            initialValues={{
              email: currentUser.email,
              username: currentUser.username,
            }}
            validationSchema={profileSchema}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field
                  name="username"
                  type="text"
                  placeholder="Username"
                  component={Input}
                />
                <Field
                  name="email"
                  type="email"
                  placeholder="Email"
                  component={Input}
                />
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  fullWidth
                  variant="primary"
                  loading={false}
                >
                  Looks Good
                </Button>
              </Form>
            )}
          </Formik>
        </FormWrapper>
      </DialogContainer>
    </DialogWrapper>
  );
};

export default IntroduceUserDialog;
