import styled from "styled-components";

import { MAX_WIDTH } from "../style";

export const RoutesWrapperStyle = styled.main`
  grid-area: main;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  height: 100%;
`;
